<template>
  <v-app>
    <v-main :class="{ guess: guessHover, showguess: ShowGuess }">
      <div class="centered">
        <a
          class="download-programme"
          href="https://whodunnit.coliseum.org.uk/Whodunnit-at-the-Coliseum-Programme.pdf"
          target="_blank"
        >
          <img
            src="@/assets/download-programme.png"
            alt="Download the programme"
            width="100%"
          />
        </a>
        <v-responsive :aspect-ratio="41 / 35">
          <div class="video-block">
            <v-responsive :aspect-ratio="16 / 9">
              <transition name="fade">
                <div class="videos fill-height" v-show="!ShowGuess">
                  <div
                    class="window"
                    :class="{ paused: IO.Paused }"
                    @click="pauseplayAll"
                  ></div>
                  <transition name="fadebuffer">
                    <div class="buffering" v-show="IO.Buffering"></div>
                  </transition>
                  <vimeo-player
                    ref="PreGuessVideo"
                    video-id="532602061"
                    class="video-embed"
                    :options="{
                      responsive: true,
                      controls: false,
                      autopause: false
                    }"
                    @ended="ShowGuess = true"
                    @bufferstart="SetBuffering(true)"
                    @bufferend="SetBuffering(false)"
                  />
                  <span
                    class="captions"
                    :class="{ enabled: Captions }"
                    @click="toggleCaptions"
                    >Captions</span
                  >
                </div>
              </transition>
              <transition name="fade">
                <v-container class="guesses" v-show="ShowGuess">
                  <v-row>
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="fail('stanley')"
                    >
                      <img
                        src="@/assets/characters/stanley.png"
                        alt="Photo of Stanley Brown"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="win('michael')"
                    >
                      <img
                        src="@/assets/characters/michael.png"
                        alt="Photo of Michael Holdsworth"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="fail('shirley')"
                    >
                      <img
                        src="@/assets/characters/shirley.png"
                        alt="Photo of Shirley Morris"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="fail('grace')"
                    >
                      <img
                        src="@/assets/characters/grace.png"
                        alt="Photo of Grace Marple"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="fail('ethel')"
                    >
                      <img
                        src="@/assets/characters/ethel.png"
                        alt="Photo of Ethel Higgins"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="character"
                      @mouseover="guessHover = true"
                      @mouseleave="guessHover = false"
                      @click="fail('all')"
                    >
                      <img
                        src="@/assets/characters/all.png"
                        alt="Photo of all characters"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </transition>
            </v-responsive>
          </div>
        </v-responsive>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "TheGuess",
  title: "The Guess",
  components: {},
  data() {
    return {
      guessHover: false,
      ShowGuess: false,
      Captions: false,
      IO: {
        Paused: true,
        Buffering: true
      }
    };
  },
  methods: {
    pauseplayAll() {
      if (!this.IO.Paused) {
        Promise.allSettled([this.$refs.PreGuessVideo.pause()]).then(() => {
          this.IO.Paused = true;
        });
      } else {
        this.$refs.PreGuessVideo.play().then(() => {
          this.IO.Paused = false;
        });
      }
    },
    SetBuffering(val) {
      this.IO.Buffering = val;
    },
    fail(character) {
      // this.$router.push("/oops");
      this.$gtag.event("guess_character", { character: character });
      this.$router.push({
        path: "/oops",
        query: { d: this.$store.state.viewingdate }
      });
    },
    win(character) {
      //this.$router.push("/ending");
      this.$gtag.event("guess_character", { character: character });
      this.$router.push({
        path: "/ending",
        query: { d: this.$store.state.viewingdate }
      });
    },
    toggleCaptions() {
      if (this.Captions) {
        console.log("Disable captions");
        this.$refs.PreGuessVideo.player.disableTextTrack().then(() => {
          this.Captions = false;
          this.$store.commit("disablecc");
        });
      } else {
        console.log("Enable captions");
        this.$refs.PreGuessVideo.player.enableTextTrack("en-GB").then(() => {
          this.Captions = true;
          this.$store.commit("enablecc");
        });
      }
    }
  },
  mounted() {
    this.$refs.PreGuessVideo.play().then(() => {
      this.IO.Paused = false;
    });
    if (this.$store.state.captions) {
      this.$refs.PreGuessVideo.player.enableTextTrack("en-GB").then(() => {
        this.Captions = true;
      });
    } else {
      this.$refs.PreGuessVideo.player.disableTextTrack().then(() => {
        this.Captions = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fadebuffer-enter-active,
.fadebuffer-leave-active {
  transition: 0.7s;
}
.fadebuffer-enter, .fadebuffer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

main {
  height: 100vh;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-no-lines.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    transition: 1s;
    filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
    -webkit-filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-suspect.png) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    transition: 1s;
    opacity: 0;
    filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
    -webkit-filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
  }

  &.showguess:after {
    opacity: 1;
  }

  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px 7px 14px;
  }

  .videos:hover .captions {
    opacity: 1;
  }

  .window {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: url(../assets/cursor-pause.png), auto;

    &.paused {
      cursor: url(../assets/cursor-play.png), auto;
    }
  }

  .buffering {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: url(../assets/loading-video-v2.png) no-repeat;
    background-size: 100% 100%;
  }

  &.guess {
    &:before {
      filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
      -webkit-filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
    }
    .download-programme {
      filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
      -webkit-filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
    }
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-52%));
  width: 55.2%;
  //min-width: 55.2%;
  z-index: 1;
}
.download-programme {
  display: block;
  position: absolute;
  top: 4px;
  right: -7px;
  width: 42%;
  z-index: 100;
  transition: 0.6s ease-out;
  filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
  -webkit-filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);

  &:hover {
    filter: none !important;
    -webkit-filter: none !important;
  }
}

.vimeo-embed {
  position: absolute;
  top: 0;
  left: 4px;
}

.captions {
  display: block;
  opacity: 0;
  width: 155px;
  height: 92px;
  position: absolute;
  bottom: 25px;
  right: 15px;
  z-index: 10;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: url(../assets/enable-cc.png) no-repeat;
  background-size: contain;
  cursor: pointer !important;
  transition: 0.3s;

  &.enabled {
    background: url(../assets/disable-cc.png) no-repeat;
    background-size: contain;
  }
}
.guesses {
  position: absolute;
  top: 0;
  left: 4px;
}

.character {
  position: relative;
  filter: sepia(0.2) brightness(0.7) grayscale(0.3);
  -webkit-filter: sepia(0.2) brightness(0.7) grayscale(0.3);
  cursor: pointer;
  transition: 0.6s;

  img {
    width: 100%;
    cursor: pointer;
  }

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
}
</style>
